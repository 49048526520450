import {
  IconDefinition,
  faArchive,
  faCalculator,
  faCar,
  faClipboardList,
  faCog,
  faComments,
  faEnvelope,
  faEuroSign,
  faFilePdf,
  faHome,
  faList,
  faPercent,
  faPiggyBank,
  faPlusSquare,
  faQuestionCircle,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

type SlashString = string & { startsWithSlash: true };

interface ItemMenuItem {
  type: 'item';
  tnKey: string;
  path?: SlashString | null;
  icon?: IconDefinition;
  permissions?: string[];
  children?: ItemMenuItem[];
}

interface DividerMenuItem {
  type: 'divider';
  permissions?: string[];
}

interface HeaderMenuItem {
  type: 'header';
  tnKey: string;
  icon?: IconDefinition;
  permissions?: string[];
}

export type MenuItem = ItemMenuItem | DividerMenuItem | HeaderMenuItem;

function createItemMenuItem(
  tnKey: string,
  path?: string | null,
  icon?: IconDefinition,
  children?: ItemMenuItem[],
  permissions?: string[],
): ItemMenuItem {
  return {
    type: 'item',
    tnKey,
    path: path as SlashString,
    icon,
    children,
    permissions,
  };
}

const menuItems: MenuItem[] = [
  createItemMenuItem('menu.sidebar.dashboard', '/dashboard', faHome),
  {
    type: 'divider',
  },
  {
    type: 'header',
    tnKey: 'menu.sidebar.myProfile',
  },
  createItemMenuItem('menu.sidebar.personalInformation', '/user', faUser),
  createItemMenuItem('menu.sidebar.taxCard', '/taxcard', faPercent),
  createItemMenuItem('menu.sidebar.yelInsurances', '/user/insurances', faClipboardList),
  createItemMenuItem('menu.sidebar.settings', null, faCog, [
    createItemMenuItem('menu.sidebar.paydaySettings', '/user/paydays'),
    createItemMenuItem('menu.sidebar.notificationSettings', '/user/notifications'),
  ]),
  {
    type: 'divider',
  },
  {
    type: 'header',
    tnKey: 'menu.sidebar.sales',
  },
  createItemMenuItem(
    'menu.sidebar.invoices',
    null,
    faEnvelope,
    [
      createItemMenuItem('menu.sidebar.allInvoices', '/invoices'),
      createItemMenuItem('menu.sidebar.newInvoice', '/invoices/edit', faPlusSquare),
    ],
  ),
  {
    type: 'divider',
  },
  {
    type: 'header',
    tnKey: 'menu.sidebar.expensesAndTrips',
  },
  createItemMenuItem('menu.sidebar.expensesAndTrips', null, faCar, [
    createItemMenuItem('menu.sidebar.allExpensesAndTrips', '/expenses', faList),
    createItemMenuItem('menu.sidebar.newExpenseAndTrip', '/expenses/add', faPlusSquare),
    createItemMenuItem('menu.sidebar.archive', '/expenses/archive', faArchive),
  ]),
  {
    type: 'divider',
  },
  {
    type: 'header',
    tnKey: 'menu.sidebar.salaries',
  },
  createItemMenuItem('menu.sidebar.allSalaries', '/salaries', faEuroSign),
  createItemMenuItem('menu.sidebar.withdrawSalary', '/salaries/add', faPlusSquare),
  createItemMenuItem('menu.sidebar.balance', '/salaries/saldo', faPiggyBank),
  {
    type: 'divider',
  },
  {
    type: 'header',
    tnKey: 'menu.sidebar.others',
  },
  createItemMenuItem('menu.sidebar.clients', '/clients', faUsers, [
    createItemMenuItem('menu.sidebar.allClients', '/clients'),
    createItemMenuItem('menu.sidebar.newClient', '/clients/edit', faPlusSquare),
  ]),
  {
    type: 'header',
    tnKey: 'menu.sidebar.admin',
    permissions: ['admin', 'support'],
  },
  createItemMenuItem('menu.sidebar.admin', '/admin', undefined, undefined, ['admin', 'support']),
  createItemMenuItem('menu.sidebar.adminusers', '/admin/users', undefined, undefined, ['admin', 'support']),
];

const topMenuItems = [
  createItemMenuItem('menu.topbar.salaryCalculator', '/dashboard', faCalculator),
  createItemMenuItem('menu.topbar.infoFiles', '/dashboard', faFilePdf),
  createItemMenuItem('menu.topbar.info', '/dashboard', faQuestionCircle),
  createItemMenuItem('menu.topbar.messages', '/dashboard', faComments),
];

export { menuItems, topMenuItems };
