'use client';

import {
  Box,
  Flex,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  VStack,
  useDisclosure,
  Collapse,
  List,
  ListItem,
  Divider,
  Heading,
  useMediaQuery,
  useTheme,
  Center,
} from '@chakra-ui/react';
import {
  faAngleDown, faAngleUp, faBars, faEllipsis, faEllipsisV, faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '@chakra-ui/next-js';
import { menuItems, topMenuItems } from '@/app/menuitems';
import type { MenuItem } from '@/app/menuitems';
import { FooterBar } from './FooterBar';
import AuthButton from '../components/auth-btn';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { ExitImpersonate } from '@/components/ActionIcons';
import { getUserData } from '@/app/user/actions';

const MenuIcon = () => <Icon as={() => <FontAwesomeIcon icon={faBars} />} />;
const CloseIcon = () => <Icon as={() => <FontAwesomeIcon icon={faXmark} />} />;
const DotsMenuIcon = () => <Icon as={FontAwesomeIcon} icon={faEllipsis} />;

const SidebarItem = ({ item }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  if (item.type === 'divider') {
    return <Divider borderColor='gray.200' />;
  }

  if (item.type === 'header') {
    return <Heading as='h2' color="black" fontSize="xs" mt="4" mb='4' textTransform='uppercase'>{ t(item.tnKey) }</Heading>;
  }

  if (item.children) {
    return (
      <Box w='100%'>
        <ListItem cursor="pointer" onClick={handleToggle}>
          <Flex alignItems='center' justifyContent='space-between'>
            <Flex alignItems='center'>
              { item.icon && <Icon as={FontAwesomeIcon} icon={item.icon} /> }
              <Text ml="2">{ t(item.tnKey) }</Text>
            </Flex>
            { isOpen
              ? <Icon as={FontAwesomeIcon} icon={faAngleUp} />
              : <Icon as={FontAwesomeIcon} icon={faAngleDown} />
            }
          </Flex>
        </ListItem>
        <Collapse in={isOpen}>
          <List ml="4" styleType="none">
            {item.children.map((childItem) => (
              <SidebarItem key={t(childItem.tnKey)} item={childItem} />
            ))}
          </List>
        </Collapse>
      </Box>
    );
  }

  return (
    <ListItem cursor="pointer" mb='2'>
      <Link href={item.path} style={{ textDecoration: 'none' }}>
        <Flex alignItems='center'>
          { item.icon && <Icon as={FontAwesomeIcon} icon={item.icon} /> }
          <Box ml="1">{ t(item.tnKey) }</Box>
        </Flex>
      </Link>
    </ListItem>
  );
};

const SidebarMenu = ({ menuItems }) => {
  const { data: session } = useSession();
  const userRole = session?.user?.role;

  const filteredMenuItems = menuItems.filter((item) => {
    // If no permissions are specified, show the item to all users
    if (!item.permissions) return true;

    // Check if the user's role is included in the item's permissions
    return item.permissions.includes(userRole);
  });

  return (
    <VStack spacing={2} alignItems="flex-start">
      {filteredMenuItems.map((item, index) => (
        <SidebarItem key={index} item={item} />
      ))}
    </VStack>
)};

const SidebarIcons = ({ menuItems }) => (
    <VStack spacing={5} alignItems='flex-start'>
      {menuItems.map((item, index) => {
        if (item.type === 'item') {
          return item.icon && <Icon as={FontAwesomeIcon} icon={item.icon} key={index} />;
        }
        if (item.type === 'divider') {
          return <Divider borderColor='gray.200' key={index} />;
        }
        return '';
      })}
    </VStack>
);

const Sidebar = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const [isMdLg] = useMediaQuery(`(min-width: ${theme.breakpoints.md}) and (max-width: ${theme.breakpoints.lg})`);
  const [isHovered, setIsHovered] = useState(false);
  /*
  const [isMdLg, setIsMdLg] = useState(() => {
    const mediaQuery = window.matchMedia('(min-width: 48em) and (max-width: 64em)');
    return mediaQuery.matches;
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 48em) and (max-width: 64em)');

    const handleMediaQueryChange = (e) => {
      setIsMdLg(e.matches);
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChange);

    return () => mediaQuery.removeEventListener('change', handleMediaQueryChange);
  }, []);
  */

  return (
    <Box
      as='aside'
      bg="white"
      h="100%"
      w='260px'
      minW={{ md: isHovered ? '260px' : '65px', lg: '260px' }}
      maxW={{ md: isHovered ? '260px' : '65px', lg: '260px' }}
      position='fixed'
      left={{ base: '0', lg: 'auto' }}
      top={{ base: '56px', md: '80px' }} // Adjusted top position
      boxShadow="lg"
      p="4"
      display={{ base: isOpen ? 'block' : 'none', md: 'block', lg: 'block' }}
      zIndex={2} // Ensure sidebar is above other content
      onMouseEnter={isMdLg ? () => setIsHovered(true) : undefined} // Handle hover state
      onMouseLeave={isMdLg ? () => setIsHovered(false) : undefined} // Handle hover state
    >
      <List>
        { (!isMdLg || isHovered) && <SidebarMenu menuItems={menuItems} /> }
        { (isMdLg && !isHovered) && <SidebarIcons menuItems={menuItems} /> }
      </List>
    </Box>
  );
};

const TopBar = ({ isOpen, onToggle, onNavToggle }) => (
    <Flex
      bg="white"
      color="black"
      alignItems="center"
      justifyContent="space-between"
      p="4"
      zIndex={1} // Ensure top bar is above sidebar
      position="fixed"
      top="0"
      left="0"
      right="0"
      display={{ base: 'flex', md: 'none' }} // Display only on smallest screens
    >
      <IconButton
        aria-label="Open Sidebar"
        icon={ isOpen ? <CloseIcon /> : <MenuIcon />}
        variant="ghost"
        colorScheme="white"
        onClick={onToggle}
      />
        <Flex>
        <Box>
          <Image
            src='https://omapajaportal.tndev.iggo.fi/img/adminmart/omapaja-tunnus-sininen.png?1616571917'
            height='45'
            width='45'
            alt='Logo'
          />
        </Box>
        <Box ml='3'>
          <Image
            src='https://omapajaportal.tndev.iggo.fi/img/adminmart/omapaja-logo-sininen-pieni.png?1616571917'
            height='45'
            width='112'
            alt='Omapaja'
          />
        </Box>
        </Flex>
      <IconButton
        aria-label="More options"
        icon={<DotsMenuIcon />}
        variant="ghost"
        colorScheme="white"
        onClick={onNavToggle}
      />
    </Flex>
);

const MenuItem = ({
  children, isLast, to = '/dashboard', icon, ...rest
}: any) => (
      <Link href={to} ml={10} style={{ textDecoration: 'none' }}>
        <Flex alignItems='center'>
          <Text display="block" {...rest} color='white'>
              {children}
          </Text>
          {icon && <Icon as={FontAwesomeIcon} icon={icon} pl={2} />}
        </Flex>
      </Link>
);

const TopNav = ({ isOpen, onToggle }) => {
  const { t } = useTranslation();
  const { data: session } = useSession();
  const foo = useSession();
  const [impersonatedName, setImpersonatedName] = useState();

  useEffect(() => {
    const getImpersonatedUser = async () => {
      const userData = await getUserData();
      setImpersonatedName(`${userData.firstName} ${userData.lastName}`);
    };
    getImpersonatedUser();
  });

  const handleExitImpersonate = async () => {
    try {
      const response = await fetch('/api/impersonate', {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to drop impersonation ID');
      }

      const data = await response.json();
      window.location.reload();
    } catch (error) {
      console.error('Error updating impersonation:', error);
    }
  };

  return (
    <Flex
      bg="blue.900"
      color="white"
      alignItems='center'
      justifyContent="space-between"
      zIndex={1} // Ensure top navigation is above sidebar
      position='fixed'
      w="100%"
      boxShadow='0 2px 4px var(--chakra-colors-blackAlpha-200)'
      lineHeight='80px'
      top={{ base: '56px', md: '0' }} // Adjust top positioning to avoid overlap with TopBar on smallest screens
      display={{ base: isOpen ? 'flex' : 'none', md: 'flex' }} // Display only on medium and large screens
    >
      <Link href='/dashboard'>
        <Flex
          background={'white'}
          h='80px'
          w={{ base: 'none', md: 'flex', lg: 'flex' }}
          minW={{ base: 'none', lg: '260px' }}
          alignItems='center'
          justifyContent='center'
          mr='auto'
        >
          {/* Logo and brand images */}
          <Box display={{ base: 'none', md: 'flex' }} minW={{ base: '65px', lg: '0' }} justifyContent='center'>
            <Image
              src='https://omapajaportal.tndev.iggo.fi/img/adminmart/omapaja-tunnus-sininen.png?1616571917'
              height='45'
              width='45'
              alt='Logo'
            />
          </Box>
          <Box display={{ base: 'none', lg: 'flex' }} ml='2'>
            <Image
              src='https://omapajaportal.tndev.iggo.fi/img/adminmart/omapaja-logo-sininen-pieni.png?1616571917'
              height='45'
              width='112'
              alt='Omapaja'
            />
          </Box>
        </Flex>
      </Link>
      { session?.user.impersonationId ? ((
        <>
          <Box ml={5}>{session?.user?.impersonationId} - { impersonatedName }</Box>
          <Box><IconButton aria-label='Lopeta impersonointi' icon={<ExitImpersonate />} onClick={() => handleExitImpersonate()} /></Box>
        </>
      )) : (<></>)}

      <Flex spacing={8} flexGrow={1} justify="flex-end" mr={5} alignContent='space-evenly'>
        {topMenuItems.map((item, index) => (
          <MenuItem key={index} icon={item.icon} to={item.path}>{ t(item.tnKey) }</MenuItem>
        ))}
            <MenuItem isLast><AuthButton /></MenuItem>
      </Flex>
    </Flex>
  );
};

const Layout = ({ children }) => {
  const router = useRouter();
  const { data: session, status } = useSession();

  const { isOpen, onToggle, onClose } = useDisclosure();
  const { isOpen: isNavOpen, onToggle: onNavToggle } = useDisclosure();

  if (status === 'loading') {
    return (
      <Center>
        <p>Loading...</p>
      </Center>
    );
  }

  if (!session) {
    router.push('/');
    return null;
  }

  return (
    <Stack spacing="0">
      <TopBar isOpen={isOpen} onToggle={onToggle} onNavToggle={onNavToggle} />
      <TopNav isOpen={isNavOpen} onToggle={onToggle} />
      <Flex >
        <Sidebar isOpen={isOpen} onClose={onClose} />
        <Box as='main' mt='80px' ml={{ base: '0', md: '80px', lg: '260px' }} p="4" w="full">
          {/* Main Content */}
          {children}

          <Divider p={5} borderColor={'black.500'} width={'auto'} />
          <FooterBar />
        </Box>
      </Flex>
    </Stack>
  );
};

export default Layout;
