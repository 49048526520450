import { Icon } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashCan,
  faCircleCheck,
  faEye,
  faPersonBooth,
  faUserTimes,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons';

export function TrashCan() {
  return <Icon as={() => <FontAwesomeIcon icon={faTrashCan} />} />;
}

export function CircleCheck() {
  return <Icon as={() => <FontAwesomeIcon icon={faCircleCheck} />} />;
}

export function View() {
  return <Icon as={() => <FontAwesomeIcon icon={faEye} />} />;
}

export function Edit() {
  return <Icon as={() => <FontAwesomeIcon icon={faPenToSquare} />} />;
}

export function Impersonate() {
  return <Icon as={() => <FontAwesomeIcon icon={faPersonBooth} />} />;
}

export function ExitImpersonate() {
  return <Icon as={() => <FontAwesomeIcon icon={faUserTimes} />} />;
}
