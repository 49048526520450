import { useSession, signIn, signOut } from 'next-auth/react';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function AuthButton() {
  const { t } = useTranslation();

  const { data: session } = useSession();
  if (session) {
    return (
            <>
                <Button
                  variant="solid"
                  colorScheme="#182868;"
                  size="sm"
                  onClick={() => signOut({ callbackUrl: '/' })}
                >
                  { t('menu.topbar.logout') }
                </Button>
            </>
    );
  }
  return (
        <>
            <Button
              variant="solid"
              colorScheme="#182868;"
              size="sm"
              onClick={() => signIn('cognito', { callbackUrl: '/dashboard' })}
            >
              { t('menu.topbar.login') }
            </Button>
        </>
  );
}
